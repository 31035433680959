<template>
    <div>
        <v-snackbar
            v-model="snackbar"
            :timeout="5000"
            :color="color"
        >
            {{ validation_message }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >Cerrar</v-btn>
            </template>
        </v-snackbar>
        <div class="row justify-content-center">
            <div class="col-md-4">
                <div class="card card-login" style="min-height:350px; width:500px !important;">
                <div class="card-header card-header-blue text-center">
                    <h4 class="card-title">INGRESO AL SISTEMA</h4>
                </div>
                <div class="card-body ">
                    <!-- <img src="@/assets/lapsa.png" style="display:block;margin:auto;" alt=""> -->
                    <img src="@/assets/logos/logo-peninsula.png" style="display:block;margin:auto;width: 200px;" alt="">
                    <span class="bmd-form-group">
                        <div class="input-group">
                            <div class="input-group-prepend">
                            <span class="input-group-text">
                                <i class="material-icons">person</i>
                            </span>
                            </div>
                            <input 
                                autofocus 
                                type="email" 
                                class="form-control" 
                                placeholder="Usuario" 
                                v-model="credentials.user"
                                @keyup.enter="openSession"
                            >
                        </div>
                    </span>
                    <span class="bmd-form-group">
                        <div class="input-group">
                            <div class="input-group-prepend">
                            <span class="input-group-text">
                                <i class="material-icons">lock_outline</i>
                            </span>
                            </div>
                            <input 
                                type="password" 
                                class="form-control" 
                                placeholder="Password" 
                                v-model="credentials.password" 
                                :rules="passwordRules"
                                @keyup.enter="openSession"
                            >
                        </div>
                    </span>
                </div>
                <div class="card-footer justify-content-center">
                    <button class="btn btn-default btn-link btn-lg" @click="reset">Cancelar<div class="ripple-container"></div></button>
                    <button class="btn btn-info btn-link btn-lg" 
                        @click="openSession"
                    >
                        Ingresar
                        <div class="ripple-container"></div>
                    </button>
                </div>
              </div>
            </div>
        </div>
    </div>
</template>
<script>
import {mapActions, mapState, mapMutations} from 'vuex'
import axios from 'axios'
export default {
    data:()=>({
        wellboats: [],
        snackbar: null,
        color: 'green',
        validation_message: '',
        valid: false,
        lazy: false,
        credentials:{
            user: '',
            password: '',
            wellboat: {},
        },
        passwordRules: [
            v => !!v || 'El Password es Obligatorio'
        ],
    }),
    mounted() {
    },
    methods:{
        async openSession () {
            let response = null;
            try{
                console.log(this.base_url)
                response = await axios.post(this.base_url+'login', {
                    email: this.credentials.user,
                    password: this.credentials.password,
                    device_name: 'browser'
                });
                let data = response.data;
                if(data.user.wellboat == null){
                    data.user.wellboat = {id: 0, nombre: 'Todos'};
                }
                this.iniciarSession(response.data);
                this.getWellboats();
                this.$store.dispatch('acopio/fetchAcopios');
                this.$store.dispatch('articulo/fetchArticulos');
                this.$store.dispatch('bodega/fetchBodegas');
                this.$store.dispatch('centro_costo/fetchCentroCostos');
                this.$store.dispatch('centro/fetchCentros');
                this.$store.dispatch('cuenta/fetchCuentas');
                this.$store.dispatch('empresa/fetchEmpresas');
                this.$store.dispatch('especie/fetchEspecies');
                this.$store.dispatch('moneda/fetchMonedas');
                this.$store.dispatch('muelle/fetchMuelles');
                this.$store.dispatch('planta/fetchPlantas');
                this.$store.dispatch('sentido/fetchSentidos');
                this.$store.dispatch('tipo_costo/fetchTipoCostos');
                this.$store.dispatch('tipo_destino/fetchTipoDestinos');
                this.$store.dispatch('tipo_origen/fetchTipoOrigenes');
                this.$store.dispatch('tipo_parte/fetchTipoPartes');
                this.$store.dispatch('wellboat/fetchWellboats');

                this.showSnackBar(false);
            }catch(error){
                response = error.response;
                this.validation_message = response.status + ': ' + response.data.message;
                this.showSnackBar(true);
            }
        },
        reset () {
            this.$refs.form.reset()
        },
        showSnackBar(error){
            if(error){
                this.color = 'red';
            }else{
                this.color = 'green';
                this.validation_message = "Ejecutado exitosamente!";
            }
            this.snackbar = true;
        },
        ...mapActions(['iniciarSession','getWellboats']),
        ...mapMutations([]),
    },
    computed: {
        ...mapState(['base_url', 'headers','authenticated'])
    },
    components:{
    }
}
</script>
<style>
    .card-header-blue{
        background: linear-gradient(60deg, #37474F, #263238) !important;
    }
    .form-control, .is-focused .form-control {
        background-image: linear-gradient(to top,#37474F 2px, rgba(156, 39, 176, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px) !important;
    }
    .btn.btn-blue {
        color: #fff;
        background-color: #1f1e62;
        border-color: #1f1e62;
        box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 0.14), 0 3px 1px -2px rgb(31, 30, 98,0.2), 0 1px 5px 0  rgb(31, 30, 0.12) !important;
    }
    .btn.btn-blue:hover {
        color: #fff;
        background-color: #304280;
        border-color: #293b7e;
        box-shadow: 0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2);
    }
    .cien{
        width: 100%;
    }
</style>
